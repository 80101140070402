import React, { Component, useRef } from 'react';
import Toggle from 'react-toggle';
import SettingsEditor from './SettingsEditor';
import VideoPreview from './VideoPreview';
import "react-toggle/style.css";
import Support from './Support';
import { deleteFetch, patchFetch, postFetch, putFetch } from '../helpers/request';
import './Settings.css';
import { Card, Button, Link, Banner, Tabs, DisplayText, FormLayout, Subheading, Heading, Stack, Spinner, Modal, Layout, Icon, TextStyle, List, Select} from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';
import PropTypes from "prop-types";
import HelpInfoModal from "./HelpInfoModal";
import stepOneImage from '../images/pdtvid1.svg';
import stepTwoImage from '../images/pdtvid2.svg';
import { getFetch } from '../helpers/request';

type Dictionary = { [index: string]: any }

interface SettingsProps {
  selectedTabIndex?: number
}

interface SettingsState {
  themeSettingsURL: string,
  themeSupportAppExtensions: boolean,
  settings: Dictionary,
  hasLoaded: boolean,
  errorLoading: boolean,
  isRefreshing: boolean,
  showBanner: boolean,
  selectedTabIndex: number,
  orderChangedKey: string,
  isSavingSettings: boolean,
  hasSettingsChanged: boolean,
  isEnabled: boolean,
  enableLoading: boolean,
  copyToClipboardText: string,
  //settings
  display_type: string,
  button_bgColor: any,
  button_fgColor: any,
  bg_overlay:any,
  autoplay: boolean,
  mute: boolean,
  show_controls: boolean,
  loop:  boolean,
  helpModalActive: boolean,
}

class Settings extends Component <SettingsProps, SettingsState> {
  
  static contextTypes = { polaris: PropTypes.object, };
  previewRef:any;

  defaultSettings: any = {
    display_type: 'inline',
    button_bgColor: {r:0,g:0,b:0,a:0.8},
    bg_overlay: {r:0,g:0,b:0,a:0.8},
    button_fgColor: {r:255,g:255,b:255,a:1},
    autoplay: true,
    mute: true,
    show_controls: true,
    loop: true
  };

  constructor(props: SettingsProps) {
    super(props);
    this.state = { 
      themeSettingsURL: '',
      themeSupportAppExtensions: false,
      settings: {},
      enableLoading: false,
      hasLoaded: false,
      errorLoading: false,
      isRefreshing: false,
      showBanner: true,
      selectedTabIndex: this.props.selectedTabIndex === undefined ? 0 : this.props.selectedTabIndex!,
      orderChangedKey: '' + new Date().valueOf(),
      isSavingSettings: false,
      hasSettingsChanged: false,
      isEnabled: false,
      copyToClipboardText: 'Copy to Clipboard',
      //settings
      display_type: '',
      button_bgColor: {},
      button_fgColor: {},
      bg_overlay: {},
      autoplay: true,
      mute: true,
      show_controls: true,
      loop: true,
      helpModalActive:false
    };
    this.previewRef = React.createRef();
  }

  componentDidMount() {
    getFetch('/app/api/settings', {})
    .then((res:any) => res.json()
      ).then(data => {
          this.setState({themeSettingsURL: 'https://admin.shopify.com/store/' + data.shopify_url.split('.')[0] +'/themes/' + data.theme_id + '/editor?context=apps'});
          this.setState({themeSupportAppExtensions: data.theme_supports_app_extensions});
          this.setState({isEnabled: data.enabled});
          let settings = JSON.parse(data.settings);
          let toUpdateDefault = false;
          for (let key of Object.keys(this.defaultSettings)) {
            if (!(key in settings)) {
              settings[key] = this.defaultSettings[key];
              toUpdateDefault = true;
            }
          }
          for (let key of Object.keys(settings)) {
            this.setState<never>({[key]: settings[key]});
          }
          this.setState({ settings: settings });
          if (toUpdateDefault) {
            this.saveSettings(settings);
          } else {
            this.setState({ hasLoaded: true });
          }
      }).catch(err => {
        this.setState({ hasLoaded: true });
        this.setState({ errorLoading: true });
      });
  }

  componentWillUnmount() {

  }


  saveSettings(settings?: any) {
    this.setState({isSavingSettings: true});
    if (settings === undefined) {
      settings = this.state.settings;
    } else {
      this.setState({settings: settings});
    }
    postFetch('/app/api/settings/',
      {settings: JSON.stringify(settings)}
    ).then((res:any) => {
      if (res.ok) {
        this.setState({ hasLoaded: true });
        this.setState({hasSettingsChanged: false});
      }
      this.setState({isSavingSettings: false});
    });
  }


  copyEmbedCode = () => {
    let textField = document.createElement('textarea');
    let text = "";
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({copyToClipboardText: 'Copied!'});
  }

  handleEnableToggle = (event:any) => {
    let isEnabled = event.target.checked;
    this.setState({isEnabled});
    this.setState({enableLoading: true});
    patchFetch('/app/api/settings/',
      {enabled: isEnabled}
    ).then(res => {
      this.setState({enableLoading: false});
    });
  }
  
  displayHelpModal = () => {
    this.setState({helpModalActive: true});
  }

  closeHelpModal = () => {
    this.setState({helpModalActive: false});
  }

  handleTabChange = (selected:number) => {
    this.setState({selectedTabIndex: selected});
  }

  handleSettingsChange = (selected:any, id:string) => {
    this.setState({hasSettingsChanged: true});
    this.setState<never>({[id]: selected});
    let settings = Object.assign({}, this.state.settings);
    settings[id] = selected;
    this.setState({settings});
    if (id === 'display_type' || id === 'autoplay' || id === 'mute' || id === 'show_controls') {
      if (this.previewRef.current) {
        this.previewRef.current.reset(settings);
      }
    } else {
      if (this.previewRef.current) {
        this.previewRef.current.resetStyles(settings);
      }
    }
    
  };

  render() {
    const mainTabs = [
      { id: 'settings', content: 'Settings', panelID: 'settings'},
      { id: 'support', content: 'FAQs and Support', panelID: 'support-panel'},
    ];

    return (
      <React.Fragment>
        {!this.state.hasLoaded && (<Loading />)}
        {!this.state.hasLoaded && (
          <div className="loader">
            <Layout><Spinner size="large" /></Layout>
          </div>
          )}
        {this.state.hasLoaded && this.state.errorLoading &&
        (
          <Banner
            title="Error loading account"
            status="critical"
          >
            <p>Please ensure that you are logged in on Shopify.</p>
          </Banner>
        )}
        {this.state.hasLoaded && (
          <React.Fragment>
            <Tabs tabs={mainTabs} selected={this.state.selectedTabIndex} onSelect={this.handleTabChange}></Tabs>
            
            {this.state.selectedTabIndex === 0 && (
              <div className="tab-zero">
                <Card sectioned>
                  <div>
                    <Heading>Publish</Heading>
                    <p><br />To enable the app, go to Online Store &gt; Themes &gt; Current Theme &gt; Customise. Then go to Theme Settings at the bottom of the left side bar, and go to the App Embeds tab on the right sidebar. There, you can toggle the switch beside Product Video Integrate on.</p>
                    <p><br />You can also <Link external url={this.state.themeSettingsURL}>go to Theme Settings by clicking here.</Link></p>
                  </div>
                </Card>
                <hr />
                <Layout>
                  <Layout.Section oneHalf>
                    <Card sectioned>
                      <br />
                      <img className="block" src={stepOneImage} />
                      <p><strong>Customise and style video player settings below</strong></p>
                      <br />
                      <p>Preview how your product video will appear</p>
                      <br />
                      <p>Select how your video will display - Inline loads videos direcly at your Product page. Lightbox / Sidebox options displays videos as a pop up. Futher customise your player settings and styles.</p>
                      <br />
                      <p>Save your changes when you are done</p>

                    </Card>
                    
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <Card sectioned>
                      <br />
                      <img className="block" src={stepTwoImage} />
                      <p><strong>Add video URL at Product image's alt text in Shopify admin</strong></p>
                      <br />
                      <p>The video element will appear at the location of the product image with the alt text.</p>
                      <p><Link onClick={this.displayHelpModal}>Learn more</Link></p>
                      <br />
                      <p>Make sure your video link is not shortened. The url should start with <strong>https://www.youtube.com/</strong> (if YouTube e.g. https://www.youtube.com/watch?v=_fJVpQwnr8M)<br/>or <strong>https://vimeo.com/</strong> (if Vimeo e.g. https://vimeo.com/429576417)<br/>or <strong>https://</strong> (if hosted on Shopify or elsewhere e.g. https://cdn.shopify.com/s/files/1/0333/9904/6279/files/plant.mp4?v=1592304451, .mp4 format recommended for maximum compatibility).</p>
                    </Card>
                  </Layout.Section>
                </Layout>
                <hr />
                <Stack distribution="equalSpacing">
                    <Stack.Item>
                      <DisplayText size="small"><strong>Style Settings</strong></DisplayText>
                    </Stack.Item>
                    <Stack.Item>
                          <Stack alignment="center" distribution="trailing">
                            <Button primary disabled={!this.state.hasSettingsChanged} loading={this.state.isSavingSettings} onClick={()=>this.saveSettings()}>{this.state.hasSettingsChanged ? 'Save' : 'Saved'}</Button>
                          </Stack>
                    </Stack.Item>
                </Stack>
                <TextStyle variation="subdued"><p>Edit your styles below and save your changes when you are ready</p></TextStyle>
                <Layout>
                <Layout.Section secondary>
                    <SettingsEditor onSettingsChange={this.handleSettingsChange}
                    settings={this.state.settings}
                    loop={this.state.loop}
                    display_type={this.state.display_type}
                    button_bgColor={this.state.button_bgColor}
                    bg_overlay={this.state.bg_overlay}
                    button_fgColor={this.state.button_fgColor}
                    autoplay={this.state.autoplay}
                    mute={this.state.mute}
                    show_controls={this.state.show_controls}>
                    </SettingsEditor>
                </Layout.Section>
                <Layout.Section>
                      <Card title="Style Preview">
                        <Card.Section>
                          <Card.Subsection>
                          <VideoPreview ref={this.previewRef} settings={this.state.settings}></VideoPreview>
                          </Card.Subsection>
                        </Card.Section>
                        <Card.Section>
                          <Card.Subsection>
                          <TextStyle variation="subdued">Image and video sizes will follow the theme that you are using. If the display type is lightbox or sidebox, the image used as a link will be the one where you add the alt text too.</TextStyle>
                          </Card.Subsection>
                        </Card.Section>
                      </Card>
                    </Layout.Section>
                </Layout>
              </div>
            )}
            {this.state.selectedTabIndex === 1 && (
              <div className="tab-faq">
                <DisplayText size="large">Support</DisplayText>
                <Support onChangeTab={this.handleTabChange} />
              </div>
            )}
          </React.Fragment>)}
          <Modal large onClose={this.closeHelpModal} open={this.state.helpModalActive} title="How to insert video link to your Shopify product"
            primaryAction={{
              content: 'Done',
              onAction: this.closeHelpModal,
            }}><HelpInfoModal></HelpInfoModal></Modal>
      </React.Fragment>
    );
  }
}

export default Settings;
